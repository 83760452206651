@import url('https://fonts.googleapis.com/css2?family=Anton&family=Lato:wght@400;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Reem+Kufi:wght@400..700&display=swap');

:root{
  --color-primary: #6f6af8;
  --color-white: #fff;
  --color-red: rgb(255, 63, 63);
  --color-gray-100: hsl(220, 20%, 10%, 5%);
  --color-gray-200: hsl(220, 20%, 10%, 10%);
  --color-gray-300: hsl(220, 20%, 10%, 25%);
  --color-gray-500: hsl(220, 20%, 10%, 50%);
  --color-gray-700: #252542;
  --color-gray-900: #0c0c22;

  --color-bg: hwb(240 95% 0%);

  --transition: all 300ms ease ;

  --container-width-lg: 84%;
  --container-width-md: 92%;
  --form-width: 50%;

  --radius-1: 0.3rem;
  --radius-2: 0.6rem;
  --radius-3: 1rem;
  --radius-4: 2rem;
  
}
*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0 ;
  box-sizing: border-box;
}

body{
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-gray-700);
}

a{
  color: var(--color-gray-700);
  text-decoration: none;
}

ul{
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
color: var(--color-gray-900);
line-height: 1.1;
}
h1{
  font-size: 2.2rem;
}
h2{
  font-size: 1.9rem;
}
h3{
  font-size: 1.3rem;
  line-height: 1.25;
}
h4{
  font-size: 1.1rem;
}
h5{
  line-height: 1.4;
}

img{
  display: block;
  object-fit: cover;
  width: 100%;
}

section{
  margin-top: 6rem;
}

.container{
  width: var(--container-width-lg);
  margin-inline: auto;
}

.btn{
  display: inline-block;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-2);
  background: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.btn.primary{
  background: var(--color-primary);
  color: var(--color-white);
}

.btn.category{
  background: var(--color-bg);
  color: var(--color-primary);
  font-size: 0.75rem;
  font-weight: 400;
}

.btn.sm{
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem;
  font-weight: 400;
}

.btn.danger{
  background: var(--color-red);
  color: var(--color-white);
}

.btn:hover{
  background: var(--color-gray-900);
  color: var(--color-white);
}

.center{
  text-align: center;
  padding: 5rem;
  margin-inline: center ;
  display: block;
  width: 100%;
}

.error-page h2{
  margin-top: 3rem;
}




/* ===== NAVBAR ===== */
nav{
 width: 100vw;
 height: 5rem;
 display: grid;
 place-items: center;
 background: var(255, 255, 255, 0.5);
 position: fixed;
 top: 0;
 z-index: 1;
 border-bottom: 4px solid var(--color-bg);
 backdrop-filter: blur(20px);
}

.nav__container{
  display: flex;
  justify-content:space-between;
  align-items: center;
  height: 100%;

}

.nav__logo {
  width: 3rem;
  display: block;
} 

.nav__menu{
  display: flex;
  align-items: center;
  gap: 3rem;
}

.nav__toggle-btn{
  display: none;
}



/*==== FOOTER ====*/
footer{
  background: var(--color-gray-900);
  margin-top: 6rem;
  padding-top: 6rem;
}

.footer__categories{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom:4rem ;
}

.footer__categories a{
  background: var(--color-gray-700);
  color: var(--color-white);
  padding: 0.6rem 1.4rem;
  border-radius: var(--radius-2);
  transition: var(--transition);
}

.footer__categories  a:hover{
  background: var(--color-white);
  color: var(--color-gray-900);
}

.footer__copyright{
  text-align: center;
  border-top: 2px solid var(--color-gray-700);
  padding: 1rem;
  color: var(--color-white);
  font-weight: 300;
}













/*==== HOME ====*/
/*==== POSTS ====*/

.posts__container { 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

.post {
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-4);
  padding-bottom: 2rem;
  transition: var(--transition);
  cursor: default;
}

.post:hover {
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}

.post__thumbnail{
border-radius: var(--radius-3);
overflow: hidden;
height: 16rem;
}

.post__content {
  margin-top: 1.5rem ;
}

.post__content h3 {
  margin: 1rem 0 0.6rem ;
}

.post__footer{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
}

.post__author {
 display: flex;
 gap: 1rem;
 align-items: flex-start;
}

.post__author-avatar{
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: var(--radius-2);
  overflow: hidden;
}










/*==== POSTS DETAILS ====*/
.post-detail{
  width: var(--form-width);
  background: var(--color-white);
  margin-inline: auto;
  padding: 2rem 0;
}

.post-detail__header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.post-detail__buttons{
  display: flex;
  gap: 1rem;
  align-items: center;
}

.post-detail__thumbnail{
  margin: 1rem 0;
  height: fit-content;
  max-height: 30rem;
  overflow: hidden;
}

.post-detail p {
  margin-bottom: 0.7rem;
}






/*==== REGISTER & FORM ====*/
.form{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
input, select, textarea {
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-1);
  resize: none;
}

.form__error-message{
  background: var(--color-red);
  color: var(--color-white);
  font-size: 0.8rem;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-1);
  display: block;
  margin-bottom: 1rem;
}

.register, .login, .create-post {
  width: var(--form-width);
  margin-inline: auto;
  height: calc(100vh - 12rem);
  display: grid;
  place-items: center;
}

.register h2, .login h2, .create-post h2{
  margin-bottom: 2rem;
}

.register small, .login small{
  display: block;
  margin-top: 1.5rem;
}

.register small a, .login small a {
  color: var(--color-primary);
}

/* custom style for react quill */

.ql-editor{
  overflow: scroll;
  background: var(--color-white);
  height: 10rem !important;
}









/*==== AUTHORS ====*/

.authors__container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.author{
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-3);
  display: flex;
  gap: 1rem;
  transition: var(--transition);
}

.author:hover{
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.07);
}

.author__avatar{
  width: 5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 0.3rem solid var(--color-bg);
  overflow: hidden;
}

.author__info p{
  margin-top: 1rem;
}




/*==== USER PROFILE ====*/

.profile__container {
  display: grid;
  place-items: center;
  text-align: center;
}

.profile__details{
  width: 100%;
}

.avatar__wrapper{
  width: 15rem;
  aspect-ratio: 1/1;
  position: relative ;
  margin-inline: auto;
  margin-top: 1rem;
}

.profile__avatar {
  height: 100%;
  border-radius: 50%;
  border: 1rem solid var(--color-white);
  overflow: hidden;
}

.avatar__form{
  height: 1rem;
}

.avatar__form input {
  visibility: hidden;
}

.avatar__form label, .profile__avatar-btn{
  background: var(--color-gray-900);
  color: var(--color-white);
  font-size: 1.3rem;
  position: absolute;
  right: 2rem;
  bottom: 1.4rem;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.profile__avatar-btn {
  cursor: pointer;
  padding: 0;
  font-size: 1.3rem;
  background: var(--color-primary);
}

.profile__details h1{
  margin-top: 1.5rem;
}

.form.profile__form{
  margin-top: 3rem;
  margin-inline: auto;
  width: var(--form-width);
}




/*==== DASHBOARD ====*/

.dashboard__container{
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.dashboard__post{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: var(--color-white);
  padding: 0.5rem 1.2rem;
  border-radius: var(--radius-3);
}

.dashboard__post-info{
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: center;
}

.dashboard__post-thumbnail{
  width: 4rem;
  border-radius: var(--radius-1);
  overflow: hidden;
}

.dashboard__post-actions{
  display: flex;
  gap: 1rem;
}






/*==== MEDIA QUERIES ====*/
@media screen and (max-width: 800px){
  h1 {
    font-size: 2rem;
  }
  h2{
    font-size: 1.6rem;
  }
  h3{
    font-size: 1.35rem;
  }
  .container{
    width: var(--container-width-md);
  }

/* NAVBAR */
.nav__container{
  position: relative;
}
.nav__menu{
  position: absolute;
  top: 5rem;
  right: 0;
  flex-direction: column;
  gap: 0;
}
.nav__menu li{
  width: 100%;
  box-shadow: -2rem 4rem 4rem rgba(0, 0, 0, 0.26);
  animation: dropDown 500ms ease forwards;
  opacity: 0;
  transform: rotateX(90deg);
  transform-origin: top;
}

@keyframes dropDown {
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

.nav__menu li:nth-child(2){
  animation-delay: 200ms;
}
.nav__menu li:nth-child(3){
  animation-delay: 400ms;
}
.nav__menu li:nth-child(4){
  animation-delay: 600ms;
}


.nav__menu li a {
  display: block;
  background: var(--color-white);
  padding: 0.85rem 2.5rem;
}



.nav__toggle-btn{
  display: inline-block;
  background: transparent;
  font-size: 1.5rem ;
  cursor: pointer;
}
.posts__container{
  grid-template-columns: 1fr;
  gap: 1rem;
}
.footer__categories{
  flex-direction: column;
}
.post-detail {
  width: 100%;
  background: transparent;
  padding: 0;
}


.register, .login, .create-post{
  width: 100%;
}

.form.profile__form{
  margin-top: 2.5rem;
  width: 100%;
}

.authors__container{
  grid-template-columns: 1fr;
  gap: 1.2rem;
}

.dashboard__post{
  flex-direction: column;
}
.dashboard__post-info{
  gap: 1.5rem;
}
.dashboard__post-thumbnail{
  width: 5rem;
}
}